import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Header class="main-container fixed w-full z-50 top-0" />
    <div className="main-container pt-20 md:pt-28 pb-10 md:pb-32 text-center">
      <p className="text-4xl text-blue-400 lg:text-blue-400 font-semibold leading-tight mb-2 mt-28 ">
        Page not found
      </p>
      <p className="text-lg text-gray-300 mb-20">
        This page do not exist. Try again or go to Home.
      </p>
      <Link
        to="/"
        className="bg-blue-400 hover:bg-gray-300 text-white text-sm-base px-12 py-4 rounded-full whitespace-nowrap"
      >
        Home
      </Link>
      <div className="bg-yellow-200 h-2 w-16 lg:w-16 rounded-full mt-8 mx-auto"></div>
    </div>
  </Layout>
)

export default NotFoundPage
